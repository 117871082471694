<template>
    <div id="courseTeaching">
        <div class="box">
            <!-- 搜索 -->
            <div class="inputIcon">
                <el-input
                    style="margin-top: 20px;"
                    placeholder=""
                    v-model="name">
                </el-input>
                <img src="@/assets/img/courseTechingimg/inputSearch.png" @click="searchIng"/>
            </div>
            <div class="screen" style="height: 100%;">
                <div style="display: flex;height: 100%;flex-wrap: wrap;justify-content: flex-start;align-items: center;">
                    <span style="float: left;padding-left: 20px;font-size: 14px;">专业:</span>
                     <div :class="{active:screenItemIndex == index}" class="screenClass" v-for="(item,index) in screenList" :key="index" @click="screenName(index,item.id)">
                        <span>{{ item.majorName }}</span>
                     </div>
                </div>
            </div>

            <ul 
                v-infinite-scroll="load" 
                style="overflow-y: auto;height: 800px;list-style: none;margin-top:15px;"
                infinite-scroll-immediate="false"
                class="ulList"
                >
                <li class="teachingCourses" v-for="(item,index) in CoursesList" :key="index" @click="CoursesClick(index,item.id)">
                    <div style="margin: 10px;">
                        <img style="height: 133px;width:205px;" :src="`${baseUrl}/${item.coverPic}`" alt="" srcset="">
                    </div>

                    <div style="float: left;margin-top: 0.8%;width: 80%;">
                        <p style="font-size: 1.3rem;font-weight: bold;padding-bottom: 5px;">{{ item.courseName }}</p>
                        <p style="padding-bottom: 5px;" ><span style="font-size: 14px;color: #666666;">课程发布时间: </span><span style="font-size: 14px;">{{ item.publishTime }}</span></p>
                        <p style="padding-bottom: 5px;"><span style="font-size: 14px;color: #666666;">作者: </span><span style="font-size: 14px;">{{ item.producer }}</span></p>
                        <p style="padding-bottom: 5px;"><span style="font-size: 14px;color: #666666;">课程依据: </span><span style="font-size: 14px;">{{ item.pursuant }}</span></p>
                        <p style="text-align: end;"><span style="font-size: 14px;color: #666666;">学习人数:</span><span>{{ item.peopleCount }}</span></p>
                    </div>
                </li>
            </ul>
            
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {get_coursePageList,get_select_majorList,get_courseDataInfo} from "@/js/homeListApi.js"
import {baseUrl} from '@/js/util.js'
import { ElMessage } from 'element-plus';
export default {
    setup () {
        const router = useRouter();
        const store = useStore();
        const state = reactive({
            baseUrl:baseUrl,
            screenItemIndex:0,
            userId:getStorage('userId'),
            screenList:[ //专业列表
                {
                    majorName:'全部',
                    id:'',
                },
            ],
            CoursesList:[ //课程教学列表
                {
                    imgUrl:require('@/assets/img/courseTechingimg/jglx.png'),
                    title:'结构力学',
                    update:'2017-01',
                    author:'范小春',
                    according:'《结构力学》',
                    numberLearners:'1886'
                },
            ],
            count:0,
            pageIndex:1,
            pageSize:10,
            name:'',//课程名称
            majorId:'',//专业id
            num:2,

        })
        const methods ={
            //获取课程教学列表
            getCoursePageList(){
                let datas = {
                    pageIndex:state.pageIndex,
                    pageSize:state.pageSize,
                    majorId:state.majorId,//专业id
                    name:state.name,//课程名称

                }
                get_coursePageList(datas).then((res)=>{
                    if (res.data.code == 1) {
                        console.log(res.data.data.list,"课程教学列表");
                        // console.log(res.data.data.totalCount.Value)
                        state.CoursesList = res.data.data.list
                        state.count = res.data.data.totalCount.Value
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //获取专业
            getMajorList(){
                let datas = {
                    departmentId:'',
                }
                get_select_majorList(datas).then((res)=>{
                    if (res.data.code == 1) {
                        console.log(res.data.data,'专业');
                        res.data.data.forEach((item)=>{
                            state.screenList.push(item)
                        })
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //点击切换专业
            screenName(index,Id){
                state.screenItemIndex = index;
                state.majorId = Id
                methods.getCoursePageList()

            },
            //点击课程 进入课程详情
            CoursesClick(index,courseId){
                // if(index==1){
                //     router.push('/home/courseDetails')
                // }else if(index==0){
                //     router.push('/home/courseDetailsCopy')
                // }
                // let datas = {
                //     courseId:courseId, //课程id
                //     userId:getStorage('userId'), //用户id
                // }
                // get_courseDataInfo(datas).then((res)=>{
                //     if (res.data.code == 1) {
                //         console.log(res.data.data,'课程详情');
                //         let courseItem = res.data.data
                //         setStorage('courseItem',courseItem)
                //         router.push('/home/courseDetailsCopy')
                //     }else{
                //         ElMessage.error(res.data.message)
                //     }
                // })
                setStorage('courseId',courseId);
                router.push('/home/courseDetailsCopy');
               
            },
            //点击搜索
            searchIng(){
                state.majorId = '';
                methods.getCoursePageList();
            },
            //下拉加载更多
            load(){
                let datas = {
                    pageIndex:state.num++,
                    pageSize:state.pageSize,
                    majorId:state.majorId,//专业id
                    name:state.name,//课程名称
                }
                get_coursePageList(datas).then((res)=>{
                    if (res.data.code == 1) {
                        res.data.data.list.forEach(item => {
                            if (item) {
                                state.CoursesList.push(item)
                            }
                        });
                        state.count += res.data.data.totalCount.Value
                        console.log(state.CoursesList,"课程教学列表222");
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
            },

        }

        onMounted(()=>{
            methods.getMajorList()
            methods.getCoursePageList()
           
        })

        return {
      ...toRefs(state),
      ...methods,
    };
    }
}
</script>

<style  lang="scss" scoped>
#courseTeaching{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
        :deep .el-input__inner {
        border-radius: 50px !important;
        border: none;
    }
    .screen{
        width: 100%;
        height: 60px;
        background: #fff;
        margin-top: 20px;
        border-radius: 5px;
        .screenClass{
            float: left;
            font-size: 14px;
            padding: 10px 20px 10px 20px;
            border-radius: 8px;
            margin-left: 20px;
            cursor: pointer;
        }
        .active{
            background: #D3EDFC;
            color: #76C8F6;
        }
    }
    .ulList::-webkit-scrollbar {
        width: 4px;
        height: 0;
        background-color: transparent;
    }
    .ulList::-webkit-scrollbar-thumb {
        border-radius: 2px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ffffff;
    }
    .ulList::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 1px transparent;
        border-radius: 10px;
        background-color: transparent;
    }

    .teachingCourses{
        width: 100%;
        height: 160px;
        background: #fff;
        margin-bottom: 10px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding:7px;
    }
    .teachingCourses:hover{
        box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.06);
    }
    .inputIcon{
        display: flex;
    justify-content: center;
    position: relative;

    }
    .inputIcon img{
        position: absolute;
        top: 25px;
        right: 10px;
        width: 30px;
        height: 30px;
        object-fit: contain;
        cursor: pointer;

    }
    }
}
</style>